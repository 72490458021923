.App {
  padding: 20px;
}

.logo {
  width: 300px;
}

nav {
  border-bottom: solid 1px #ebebeb;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

nav a {
  margin-right: 10px;
}

.project-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 40px;
}

.project-image img {
  width: 50vw;
  margin-right: 20px;
}

.project-work-log {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.project-work-log > div {
  width: 48vw;
  margin-right: 20px;
}

.project-work-log .sticky-content {
  position: sticky;
  top: 0px;
  align-self: flex-start;
}

.work-log-image {
  width: 100%;
  margin-bottom: 20px;
}

.project-page-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.date {
  font-size: 0.9em;
  color: gray;
}

.project-images {
  margin-right: 40px;
}

.project-images img {
  width: 50vw;
  display: block;
  margin-bottom: 20px;
}

p {
  max-width: 565px;
}
